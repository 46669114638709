<template>

    <section class="fullscreen d-flex">
        <div class="container align-self-center">
            <div class="row align-items-center d-flex">
                <div class="col-xl-8 col-md-12">
                    <img class="logo" alt="Duscholux Schiedam Holding logo" src="./assets/img/logo.svg">
                </div>
                <div class="col-xl-4 col-md-12">
                    <h1>Duscholux Schiedam Holding B.V.</h1>
                    <ul class="no-bullets">
                        <li>Pascalstraat 12 A </li>
                        <li>3771 RT Barneveld</li>
                        <li>Tel <a href="tel:0031850812038">+31 85 081 20 38</a></li>
                        <li><a href="mailto:info@duscholux-schiedam.nl">info@duscholux-schiedam.nl</a></li>
                    </ul>
                    <a class="btn mx-auto" href="mailto:info@duscholux-schiedam.nl">Stuur e-mail</a>
                </div>
            </div>
        </div>
    </section>
</template>
